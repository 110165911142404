@font-face {
  font-family: CeraMedium;
  src: url("/fonts/CeraRoundPro-Medium.otf");
}

@font-face {
  font-family: CeraRegular;
  src: url("/fonts/CeraRoundPro-Regular.otf");
}

h1 {
  font-family: CeraMedium;
}

#root {
  padding-left: 260px;
}

@media all and (max-width: 1200px) {
  #root {
    padding-left: 0!important;
    padding-top: 60px;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;

}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #d2255b;
  border-color: #d2255b transparent #d2255b transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: CeraRegular;
  /*position: relative;*/
  min-height: 100vh;
  background: url(/img/grey-bg.svg);
  background-size: 100vh;
  /*
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to bottom right, #fdfefe, #47b6d536);
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.row {
  margin: 0;
}

@media all and (max-width: 475px) {
  .col-12 {
    padding-right: 0;
    padding-left: 0;
  }

  .drawer-item {
    margin: 0!important;
  }
}

.wrapper {
  display: flex;
  /*display: grid;
  grid-template-columns: 1fr 20fr 1fr;

   */
}

/* DRAWER */
#drawer {
  /*position: sticky;
  position: -webkit-sticky;*/
  position: fixed;
  height: 100vh;
  float: left;
  top: 0;
  left: 0;
  width: 250px;
  min-width: 250px;
  /*width: 50px;*/
  background: url(/img/blue-bg.svg);
  background-size: cover;
  color: #FFF;
  z-index: 10;
  transition: 0.5s cubic-bezier(0, 0, 0, 0.89);
  overflow: hidden;
  margin-right: 2rem;
}

@media all and (max-width: 1200px) {
  #drawer {
    left: -280px;
    width: 250px;
    position: fixed;
  }

  .drawer-item {
    display: flex!important;
  }

  .drawer-label {
    display: inline-block!important;
  }
}

.drawer-inner {
  width: 250px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer-label {
  /*display: none;*/
  font-family: CeraMedium;
}

.logo-label {
  font-family: CeraMedium;
  font-size: 1.2rem;
}

.drawer-show {
  left: 0!important;
}

#drawer:hover {
  width: 250px;
}

#drawer:hover .drawer-label {
  display: inline-block;
}

.drawer-item {
  margin: 0.3rem 0;
  padding: 0.6rem 1rem;
  font-family: CeraMedium;
  font-size: 1rem;
  color: #fff;
  text-decoration: none!important;
  transition: 0.5s ease-in-out;
}

.drawer-item:hover{
  color: #444;
  background: rgba(255,255,255,1);
}

.drawer-item-selected {
  color: #444;
  background: rgba(255,255,255,1);
}

.hideable {
  overflow: hidden;
  height: 2.5rem;
  display: flex;
  align-items: center;
}

.profile-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*margin: 0.3rem 0;*/
  padding: 0 1rem;
  font-family: CeraMedium;
  font-size: 1.2rem;
}

/* CONTENT */

.content {
  padding: 1rem;
  min-height: 100vh;
  flex-grow: 90;
  /*overflow-y: scroll;*/
}

@media all and (max-width: 1024px) {
  .content {
    padding: 0.5rem;
  }
}

.login-form {
  max-width: 500px;
  margin: auto;
}

/* Анализы */
.line {
  transition: 1s;
  opacity: 0;
}

.line-show {
  opacity: 1;
}

.download-btn {
  font-family: CeraMedium;
  color: #47b6d4;
  text-decoration: none!important;
  transition: 0.3s;
}

.download-btn:hover {
  font-family: CeraMedium;
  color: #D2255B;
}


/* ONLINE */
.spec-item {
  cursor: pointer;
  font-family: CeraMedium;
  min-height: 80px;
}

.spec-item .fas {
  transition: 0.3s;
}

.spec-item:hover .fas {
  margin-right: 1rem;
}


#footer {
  background: rgba(255, 255, 255, 0.4) url(/img/grey-pattern.jpg);
  background-blend-mode: soft-light;
  border-top: 1px #e8e8e8 solid;
}

.dnk-blue {
  background-color: #47b6d4;
  color: #FFF;
}

.btn-outline-secondary:hover {
  background-color: #47b6d4!important;
  color: #FFF!important;
}

.dnk-blue-text {
  color: #47b6d4;
}

.dnk-pink-text {
  color: #D2255B;
}

.dnk-grey {
  background-color: #EBEBEC;
}

.dnk-grey-pattern {
  background-color: #EBEBEC;
  background: url('/img/grey-pattern.jpg');
  background-size: cover;
}

.dnk-blue-pattern {
  background-color: #EBEBEC;
  background: url('/img/blue-pattern.jpg');
  background-size: cover;
  color: #FFF;
}

.flip-card {
  perspective: 1000px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  min-height: 250px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transition: transform .8s cubic-bezier(.2,.85,.4,1.275),-webkit-transform .8s cubic-bezier(.2,.85,.4,1.275);
  -webkit-transition: transform .8s cubic-bezier(.2,.85,.4,1.275),-webkit-transform .8s cubic-bezier(.2,.85,.4,1.275);
  -moz-transition: transform .8s cubic-bezier(.2,.85,.4,1.275),-webkit-transform .8s cubic-bezier(.2,.85,.4,1.275);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  min-height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-size: cover;
  background-repeat: no-repeat;
}

/* Style the back side */
.flip-card-back {
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.padded {
  padding: 3rem!important;
}

.padded2 {
  padding: 2rem!important;
}

@media (max-width: 767px) {
  .padded {
    padding: 1rem!important;
  }
}

.dnk-blue-pattern a:hover {
  color: #fff!important;
}

.navbar-brand {
  font-family: CeraMedium;
}

.dnk-light-pink {
  background-color: #ea4378;
  color: #FFF;
}

.dnk-pink {
  background-color: #D2255B;
  color: #FFF;
}

.rounded {
  border-radius: 20px !important;
}

.top-rounded {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.headphone, .nav-item>a{
  color: white !important;
}

.future-priem {
  border: 2px solid #E91E63;
}

.recoverPswd{
  color: #FFF;
  font-weight: bold;
  text-decoration: underline;
  font-size: 18px;
}

.recoverPswd:hover{
  color: #8268ab;

}

.headphoneLink {
  color: white !important;
  font-weight: bold;
}

.oerator{
  padding: 0 10px 0 0;
}

.head-banner-dnk, .head-banner-dipplex{
  display: block;
  height: 100px;
  background-size: contain;
  margin-top: 10px;
}

.head-banner-dnk{
  background: url('/img/head-banner-dnk.png') no-repeat center;
}

.head-banner-dipplex{
  background: url('/img/head-banner-dipplex.png') no-repeat center;
  background-size: contain;
}

@media (max-width:992px){
  .head-banner-dnk, .head-banner-dipplex
  {
    display: none;
  }
}

h1, h2{
  text-align: center;
}

.blue-form{
  color: #ffffff;
  border-radius: 20px;
  background-color: #00a3e8;
  /*margin-top: 30px;*/
}

.blue-form h2{
  padding: 20px;
  text-align: center;
}

.blue-form form{
  padding: 0 30px 20px 30px;
  color: #ffffff !important;
}

.auth-select{
  text-align: center;
  padding-top:30px;
}

.blue-form .btn-default:hover{
  color: #ffffff;
}

button{
  cursor: pointer;
}

.operator-btn{
  background: url('/img/operator.png') no-repeat center center;
  height: 125px;
}

.appointment-btn{
  background: url('/img/button.png') no-repeat center center;
  height: 307px;
}

.bg-primary th{
  color: #ffffff;
}

.back-btn{
  /*font-size: 22px;*/
}

th, td {
  min-width: 120px;
  padding: 10px 20px;
}


th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

.ajax-loader{
  display: none;
  position: fixed;
  background: url('/img/fill.png');
  width: 100%;
  left: 0;
  top:0; bottom: 0;
}

.ajax-loader img{
  display: block;
  position: absolute;
  top: 50%; left: 50%;
  margin: -64px -64px 0 0;
}

.select-doctor{
  color: red;
  font-weight: bolder;
}

thead td, thead th{
  cursor: pointer;
}

.back-button{
  padding: 10px 20px;
}

.bg-primary {
  background-color: #8369ab!important;
}

h1, h2, h3, h4{
  /*color: #4e6a92;*/
  font-family: CeraMedium;
}

body{
  /*color: #4e6a92;*/
  color: #3c3f40;
}

.text-muted{
  /*color: #8368ad !important;*/
}

.btn-primary {
  background-color: #8369ab!important;
  border-color: #8369ab!important;
}

.badge-primary{
  background-color: #8369ab!important;
}

.badge-info{
  background-color: #D9ABD1!important;
}

.text-info{
  color: #D9ABD1!important;
}

.priem-row {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #D2255B;
  transition: 0.5s ease;
  -webkit-transition: 1s ease;
  transform: translateY(110%);
  -webkit-transform: translateY(110%);
}

.priem-row-filled {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

